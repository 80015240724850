import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(), _createBlock(_Teleport, {
      to: _ctx.popperContainer || '#'+_ctx.popperContainerId,
      disabled: _ctx.disabled
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["meta-popper-wrapper", {
      visible:_ctx.interVisible,
      [_ctx.customWrapperClass]:_ctx.customWrapperClass
    }]),
        style: _normalizeStyle({'z-index':_ctx.zIndex}),
        ref: "popperRef"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["meta-popper", _ctx.customPopperClass])
        }, [
          _renderSlot(_ctx.$slots, "content")
        ], 2)
      ], 6)
    ], 8, ["to", "disabled"]))
  ], 64))
}