
import {Options, Vue} from 'vue-class-component';
import anime from "animejs";

@Options<FadeTransition>({
  components: {},
  props: {
    mode:{
      required:false,
      default:'out-in',
      type:[String,undefined]
    },
    appear:{
      required:false,
      type:Boolean,
      default:false
    },
    outDelay:{
      required:false,
      type:Number,
      default:200
    },
    outDuration:{
      required:false,
      type:Number,
      default:300,
    }
  }
})
export default class FadeTransition extends Vue {
  mode!:'out-in' | 'in-out' | undefined;
  appear!:boolean;
  outDelay!:number;
  outDuration!:number;

  handleBeforeEnter(el:HTMLElement){
    // const isLeft = el.hasAttribute('left');
    anime.set(el,{
      opacity:0
    });
  }
  handleEnter(el:HTMLElement,done:() => void){
    anime({
      targets:el,
      opacity:1,
      complete:() => done(),
      duration:300,
      easing:'easeInCubic'
    });
  }
  handleBeforeLeave(el:HTMLElement){

  }
  handleLeave(el:HTMLElement,done:() => void){
    const isLeft = el.hasAttribute('left');
    anime({
      targets:el,
      opacity:[1,0],
      complete:() => done(),
      duration:this.outDuration,
      delay:this.outDelay,
      easing:'easeOutCubic'
    });
  }
}
