
import {Options, Vue} from 'vue-class-component';

@Options<WidthRelatedBox>({
  components: {},
  props: {
    height:{
      required:true,
      type:String
    },
    disable:{
      required:false,
      type:Boolean,
      default:false
    }
  }
})
export default class WidthRelatedBox extends Vue {
  height!:string;
  disable!:boolean;
}
