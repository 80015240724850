
import {Options, Vue} from 'vue-class-component';
import {ViewNames} from "@/router";

@Options<ModelTrainingProgress>({
  components: {},
  props: {},
  computed:{
    proportion(){
      const remain = 100 - this.progress;
      const remainCount = Math.floor(remain / 10);
      const completeCount = 10 - remainCount;

      return [completeCount,remainCount];
    }
  }
})
export default class ModelTrainingProgress extends Vue {
  progress = 19;
  proportion!:[number,number];

  handleTrainingProgressClicked(){
    this.$router.push({
      name:ViewNames.AboutUs
    })
  }
}
