
import {Options, Vue} from 'vue-class-component';
import {ViewNames} from "@/router";
import {configData} from "@/config";

@Options<MetaCopyright>({
  components: {},
  props: {}
})
export default class MetaCopyright extends Vue {
  ViewNames = ViewNames;
  configData = configData;
  handleBeianBtnClicked(){
    window.open('https://beian.miit.gov.cn/');
  }
}
