import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac2d5ab4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "width-related-box"
}
const _hoisted_2 = { class: "wrb-content-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.disable)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "width-related-box",
        style: _normalizeStyle({
     'padding-bottom':_ctx.height
  })
      }, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ], 4))
}