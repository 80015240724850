
import {Options, Vue} from 'vue-class-component';
import {UserInfoData} from "@/types/api/UserInfoData";
import WidthRelatedBox from "@/components/WidthRelatedBox.vue";
import {colorLog, getHashColor} from "@/utils";

@Options<UserAvatar>({
  components: {WidthRelatedBox},
  props: {
    userInfo:{
      required:true
    }
  },
  computed:{
    userIconBGColor(){
      if(this.userInfo){
        return getHashColor(this.userInfo.userName);
      }
      return '#ddd';
    }
  }
})
export default class UserAvatar extends Vue {
  userInfo:UserInfoData | null = null;
  imageLoadFailed = false;
  getHashColor = getHashColor;
  userIconBGColor!:string;

}
