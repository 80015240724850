export default {
  "3dRendering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D rendering"])},
  "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "aboutUsSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metasota -- Democratize professional knowledge with AI"])},
  "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
  "annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annually"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
  "basicMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Membership"])},
  "billingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Info"])},
  "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue"])},
  "billingInfoPlanCircumstance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This ", _interpolate(_named("planName")), " Plan is set to ", _interpolate(_named("price")), " per ", _interpolate(_named("span")), " and will renew on ", _interpolate(_named("date")), "."])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "canNotEmptyContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not rename with empty content"])},
  "clickToTry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to try"])},
  "close-ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close-ups"])},
  "changeAvatarTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Profile Photo"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "copyrightComplaints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright Complaints"])},
  "copyTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard"])},
  "copySpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy space"])},
  "creations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creations"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "credit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["credit"]), _normalize(["credits"])])},
  "creditPerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credit / month"])},
  "canvasQuota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quota for this canvas:"])},
  "canEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can edit"])},
  "canView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["can view"])},
  "creditPerMonthAndRelaxMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credit / month + Relax mode"])},
  "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "downloadInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Invoice"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "emptyRecentsTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
  "canNotSetContentWithEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not set content with empty"])},
  "freeUsePrivilege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% free for commercial and personal use"])},
  "formUserNameTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user name is 2 to 16 long and can contain letters, numbers, underscores and minus signs"])},
  "formEmailTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input valid email"])},
  "front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENERATE"])},
  "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid"])},
  "gray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gray"])},
  "hand-painted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand-painted"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "illustration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illustration"])},
  "inviteCollaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite collaborators"])},
  "inpaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inpaint"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw"])},
  "inviteCollaboratorsTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can invite your friends to draw anything with you, and you can see each other's effort in real time. Enjoy!"])},
  "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
  "limitedUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited use"])},
  "longExposure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long Exposure"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
  "logoFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IGNITE IMAGINATION"])},
  "multipleExposures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple exposures"])},
  "microscopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microscopic"])},
  "mainInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type anything, I’ll draw it for you."])},
  "membershipTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT'S INCLUDED"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "modelTrainingProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model training progress"])},
  "moneyBriefName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD"])},
  "moneyCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
  "monthlyFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly fee"])},
  "moreExamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More examples"])},
  "noMoreContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more content"])},
  "noCredits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more credits"])},
  "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["off"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "ownAllAssetsYouCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["own all Assets you create"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "payAnnually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay annually"])},
  "payMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay monthly"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment method"])},
  "paymentMethod-credit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("credit")), " ending in ", _interpolate(_named("endingNumber"))])},
  "paymentMethodUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "perCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per credit"])},
  "perspective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perspective"])},
  "pieceDeclaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created with DrawDraw, an AI system by MetaSOTA"])},
  "photorealistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photorealistic"])},
  "privateVisibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Visibility"])},
  "privateVisibilityInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The images you create are only visible to you by default."])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prompt"])},
  "promptPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter prompt"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
  "publicToCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public to community"])},
  "plain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plain"])},
  "recent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Recent"]), _normalize(["Recents"])])},
  "rear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rear"])},
  "recharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharge"])},
  "rechargePlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharge Plans"])},
  "rechargeableInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargeable credits can only be used for the month"])},
  "relaxMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax Mode"])},
  "relaxModeInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have access to 'relax' mode, which allows you to run unlimited jobs in exchange for longer wait times. While in 'relax' mode you won't be able to run 'Upscale' jobs."])},
  "relaxModeTip_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the estimated waiting time is "])},
  "relaxModeTip_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time"))])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side"])},
  "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign in first"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "signOut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sign Out ", _interpolate(_list(0))])},
  "sketch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sketch"])},
  "standardMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Membership"])},
  "standardPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard Plans"])},
  "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles"])},
  "hdTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It costs 1 credit to generate a high-resolution image."])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "subscribeCreditMetricInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*1 prompt = 1credit(6 images); 1 HD image super-resolution = 1credit"])},
  "subscribeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase a subscription"])},
  "subscribeUserCreditsLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("num")), " credits left on current account"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "subscriptionPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription plan"])},
  "subscriptionPlanCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "subscriptionPlanPrice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("price")), "/", _interpolate(_named("span"))])},
  "subscriptionReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Receipts"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
  "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
  "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
  "trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
  "tryItYourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it yourself"])},
  "unlimitedUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited use"])},
  "upscale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upscale"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
  "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "viewAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all Images"])},
  "watercolour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watercolour"])},
  "resetEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify email"])}
}