import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade-transition",
    css: false,
    appear: _ctx.appear,
    onBeforeEnter: _ctx.handleBeforeEnter,
    onEnter: _ctx.handleEnter,
    onBeforeLeave: _ctx.handleBeforeLeave,
    onLeave: _ctx.handleLeave,
    mode: _ctx.mode
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["appear", "onBeforeEnter", "onEnter", "onBeforeLeave", "onLeave", "mode"]))
}