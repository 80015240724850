
import {Options, Vue} from 'vue-class-component';
import I18nSelector from "@/components/I18nSelector.vue";
import MetaPopper from "@/components/MetaPopper.vue";
import {ViewNames} from "@/router"
import UserAvatar from "@/components/UserAvatar.vue";
import store from "@/store";
import {colorLog, deleteCookie, formatDate, isMobile} from "@/utils";
import {CreationsImagesData} from "@/types/api/CreationImagesData";
import {createCanvas, getCreations, getUserAccount, logOut, updateRelaxMode} from "@/api";
import {ElMessage} from "element-plus";
import RotateRing from "@/components/RotateRing.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import {MetaLocales} from "@/types/Lang";
import {CanvasDTO} from "@/types/api/InfiniteData";
import {MetaEvents} from "@/types/Events";
import CreationData from "@/types/api/CreationData";
import {UserAccount} from "@/types/api/UserInfoData";
import HeaderLogo from "@/components/universalUI/HeaderLogo.vue";
import {configData, Envs} from "@/config";
import ModelTrainingProgress from "@/components/ModelTrainingProgress.vue";

@Options<MetaHeader>({
  name:'MetaHeader',
  components: {
    HeaderLogo,
    I18nSelector,
    MetaPopper,
    UserAvatar,
    RotateRing,
    FadeTransition,
    ModelTrainingProgress
  },
  props: {},
  computed:{
    position(){
      return this.$route.meta.disableHeaderSticky ? 'static' : 'sticky';
    }
  }
})
export default class MetaHeader extends Vue {
  configData = configData;
  Envs = Envs;
  ViewNames = ViewNames;
  individualPopperVisible = false;
  ringPopperVisible = false;
  store = store;
  relaxMode:boolean|undefined = false;
  position!: 'sticky' | 'static';
  recentCreations:CreationsImagesData[] = [];
  MetaLocales = MetaLocales;
  initialLoadingCreations = true;
  loadingCreations=  false;
  userAccount:UserAccount | null = null;
  openInfinity = false;
  formatDate = formatDate;

  handleRelaxModeChanged(){
    updateRelaxMode(this.relaxMode as boolean).then(res=>{
      if(res.data.code===200){
        if(store.userInfo &&this.relaxMode!==undefined) {
          store.userInfo.relax = this.relaxMode;
        }
      }
    })


  }

  $refs!:{
    individualPopper:MetaPopper;
    recentPopper:MetaPopper;
  };

  handleViewAllImagesBtnClicked(){
    this.$router.push({
      name:ViewNames.Creations
    });
    this.ringPopperVisible = false;
  }

  async handleInfiniteBtnClicked(){
    const userInfo = await store.userInfoPromise;
    if(!userInfo){
      this.$router.push({
        name:ViewNames.Login
      })
      return;
    }
    createCanvas("untitled").then(res=>{
      const canvasDTO:CanvasDTO =res.data.data;
      this.$router.push({
        name:ViewNames.Fabric,
        query:{
          id:canvasDTO.id
        }
      });
    })

  }

  handleSubscribeBtnClicked(){
    this.$router.push({
      name:ViewNames.Subscribe
    })
  }

  async handleSignInBtnClicked() {
    if (store.userInfo) {
      (this.$refs.individualPopper as MetaPopper).popperInstance!.update();
      this.individualPopperVisible = !this.individualPopperVisible
      if(this.individualPopperVisible){
        const res = await getUserAccount();
        this.userAccount = res.data.data;
      }
    } else {
      this.$router.push({
        name:this.$i18n.locale==MetaLocales.EN?ViewNames.Login_en:ViewNames.Login
      })
    }
  }
  mounted(){
    this.initOpenBlockTag();
    store.userInfoPromise.then(user=>{
      this.relaxMode=user?.relax
    })
  }

  initOpenBlockTag() {
    let queryOI = this.$route.query.openInfinity
    console.log('queryOI:', queryOI)
    if (queryOI !== undefined) {
      // @ts-ignore
      localStorage.setItem('queryOI', queryOI)
    }
    let storageOI = localStorage.getItem('queryOI');
    this.openInfinity = (storageOI == null ? false : storageOI === '1');
  }

  handleRecentBtnClicked(){
    if(!store.userInfo){
      this.$router.push({
        name:ViewNames.Login
      })
      return;
    }
    this.$refs.recentPopper.popperInstance!.update();
    this.ringPopperVisible = !this.ringPopperVisible;
    if(this.ringPopperVisible && store.recentCreationsNeedRefresh){
      this.updateCreations();
    }
  }

  handleRecentImageClicked(creationData:CreationData,index:number){
    this.$router.push({name:ViewNames.Creations,params:{index}});
    this.ringPopperVisible = false;
  }

  updateCreations(){
    this.loadingCreations = true;
    store.updateRecentCreationsNeedNoticeState(false);
    getCreations(0,8).then(creations => {
      this.recentCreations = creations
    }).catch((e)=>{
      ElMessage.error(e);
      store.updateRecentCreationNeedRefreshState(true);
    }).finally(() => {
      this.initialLoadingCreations = false;
      this.loadingCreations = false
    });
  }



  handleIndividualHomeClicked(){
    this.$router.push({name:ViewNames.Profile});
    this.individualPopperVisible = false;
  }

  async handleLogOutClicked(){
    this.individualPopperVisible = false;
    try{
      await logOut();
    }catch (e){
      ElMessage.error(this.$t('singOut',[this.$t('failed')]));
      return;
    }
    let domains = ['.'+document.domain];
    if(document.domain.split('.').length > 2){
      //域名有两个及以上的点 如'clm.metago.cn' 'pop.clm.metalaw.com'
      //push的字符串都是如'.metago.cn' '.metalaw.com'
      domains.push('.' + document.domain.split('.').slice(-2).join('.'))
    }

    let cookieNames = ['uid','JSESSIONID','sid','userName','phone','email','headImgUrl','mainSiteTeamId'];
    domains.forEach(domain=>{
      for(let name of cookieNames){
        deleteCookie(name,domain);
      }
    });
    store.updateUserInfo(Promise.resolve(null));
    this.$router.push({
      name:ViewNames.Home
    });
  }
}
