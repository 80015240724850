import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb02f2c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-avatar" }
const _hoisted_2 = {
  key: 1,
  class: "handle-invalid-case"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetaImage = _resolveComponent("MetaImage")!
  const _component_WidthRelatedBox = _resolveComponent("WidthRelatedBox")!
  const _directive_containerRelateFs = _resolveDirective("containerRelateFs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WidthRelatedBox, { height: "100%" }, {
      default: _withCtx(() => [
        (_ctx.userInfo)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.userInfo.headImgUrl && !_ctx.imageLoadFailed)
                ? (_openBlock(), _createBlock(_component_MetaImage, {
                    key: 0,
                    src: _ctx.userInfo.headImgUrl,
                    onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.imageLoadFailed = true))
                  }, null, 8, ["src"]))
                : _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "capital-text-avatar",
                    style: _normalizeStyle({
                'background-color':_ctx.userIconBGColor
             })
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.userInfo.userName.slice(0,1).toUpperCase()), 1)
                  ], 4)), [
                    [_directive_containerRelateFs, .5]
                  ])
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, " Unknown "))
      ]),
      _: 1
    })
  ]))
}