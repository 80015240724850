
import {MetaLocales} from "@/types/Lang";
import {Options, Vue} from 'vue-class-component';

@Options<SlideI18nText>({
  components: {},
  props: {
    template:{
      required: false,
      type:Object,
    },
    path:{
      required:true,
      type:String
    },
    args:{
      required:false,
      type:Object,
      default:{}
    },
    count:{
      required:false,
      type:Number,
      default:1
    }
  }
})
export default class SlideI18nText extends Vue {
  locale!:string;
  count!:number;
  template?:{
    "en":{[k:string]:string},
    "zh-cn":{[k:string]:string},
  };
  args?: {[k:string]:string};

  MetaLocales = MetaLocales;
}
