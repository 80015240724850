import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77d35a04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mtp-progress-bar" }
const _hoisted_2 = {
  class: "mtp-progress-thumb",
  complete: ""
}
const _hoisted_3 = {
  class: "mtp-progress-thumb",
  remain: ""
}
const _hoisted_4 = { class: "mtp-progress-digital" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SlideI18nText = _resolveComponent("SlideI18nText")!

  return (_openBlock(), _createElementBlock("div", {
    class: "model-training-progress",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTrainingProgressClicked && _ctx.handleTrainingProgressClicked(...args)))
  }, [
    _createVNode(_component_SlideI18nText, {
      path: "modelTrainingProgress",
      class: "mtp-label"
    }),
    _createElementVNode("em", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proportion[0], (i) => {
        return (_openBlock(), _createElementBlock("span", _hoisted_2, "▓"))
      }), 256)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proportion[1], (i) => {
        return (_openBlock(), _createElementBlock("span", _hoisted_3, "░"))
      }), 256))
    ]),
    _createElementVNode("em", _hoisted_4, _toDisplayString(`${_ctx.progress}%`), 1)
  ]))
}