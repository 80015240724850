import {reactive} from 'vue';
import {UserInfoData} from "@/types/api/UserInfoData";
import {CreationsImagesData} from "@/types/api/CreationImagesData";
import {ViewNames} from "@/router"
import CreationData from "@/types/api/CreationData";
import {MetaLocales} from "@/types/Lang";
import moment from "moment";
import {BriefEmitter, colorLog} from "@/utils";

let proxyRes:(userInfo:UserInfoData) => void;
const userInfoPromise = new Promise<UserInfoData>(res => proxyRes = res);

const store = reactive<
    {
        prodImages:CreationsImagesData;
        updateProdImages(indexOrCreationImagesData: number | CreationsImagesData, creationData?: CreationData):void;

        operatingIndex:number;
        updateOperatingIndex(index:number):void;

        userInfo:UserInfoData | null;
        userInfoPromise:Promise<UserInfoData | null>;
        updateUserInfo(userInfoPromise:Promise<UserInfoData | null>):void;

        relaxMode:boolean;
        updateRelaxMode(active:boolean):void;

        recentCreationsNeedNotice:boolean;
        recentCreationsNeedRefresh:boolean;
        updateRecentCreationsNeedNoticeState(state:boolean):void;
        updateRecentCreationNeedRefreshState(state:boolean):void;

        backViewName:ViewNames | null;
        updateBackViewName(viewName:ViewNames | null):void;

        currentLang:MetaLocales;
        updateCurrentLang:(locale:MetaLocales) => void;

        eventBus:BriefEmitter;
    }
    >({
    //操作的图片
    prodImages: JSON.parse(localStorage.getItem('prodImages') || '[]') as CreationsImagesData,
    updateProdImages(indexOrCreationImagesData,creationData){
        if(typeof indexOrCreationImagesData === 'number' && creationData){
            this.prodImages.creations[indexOrCreationImagesData] = creationData;
        }else if(typeof indexOrCreationImagesData !== 'number'){
            this.prodImages = indexOrCreationImagesData;
        }
        localStorage.setItem('prodImages',JSON.stringify(this.prodImages));
    },
    operatingIndex:0,
    updateOperatingIndex(index:number){
        this.operatingIndex = index;
    },
    //UserInfo
    userInfo: null,
    userInfoPromise:userInfoPromise,
    updateUserInfo(userInfoPromise) {
        this.userInfoPromise = userInfoPromise;
        userInfoPromise.then(userInfo => this.userInfo = userInfo);
    },

    relaxMode: !!localStorage.getItem('relaxMode'),
    updateRelaxMode(active:boolean){
      this.relaxMode = active;
      if(active){
          localStorage.setItem('relaxMode','1');
      }else{
          localStorage.removeItem('relaxMode');
      }
    },

    recentCreationsNeedNotice: (JSON.parse(localStorage.getItem('recentNotice') as string)) ?? false,
    recentCreationsNeedRefresh: true,
    updateRecentCreationsNeedNoticeState(state) {
        this.recentCreationsNeedNotice = this.recentCreationsNeedRefresh = state;
        localStorage.setItem('recentNotice',state + '');
    },
    updateRecentCreationNeedRefreshState(state){
        this.recentCreationsNeedRefresh = state;
    },
    backViewName: localStorage.getItem('backViewName') as (ViewNames | null),
    updateBackViewName(viewName: ViewNames | null) {
     this.backViewName = viewName;
     if(!viewName){
         localStorage.removeItem('backViewName');
     }else{
         localStorage.setItem('backViewName',viewName as string);
     }
    },
    currentLang: localStorage.getItem('locale') as MetaLocales  ?? ( ['zh','zh-cn','zh-tw','zh-hk'].includes(navigator.language.toLocaleLowerCase())? MetaLocales['ZH-CN'] : MetaLocales.EN ),
    updateCurrentLang(locale:MetaLocales){
        this.currentLang = locale;
        localStorage.setItem('locale',locale);
        moment.updateLocale(locale,{});
    },

    eventBus:new BriefEmitter(),
});
export default store;
