
import {Options, Vue} from 'vue-class-component';
import {MetaEvents} from "@/types/Events";
import store from "@/store";
import {ViewNames} from "@/router";

@Options<HeaderLogo>({
  components: {},
  props: {}
})
export default class HeaderLogo extends Vue {
  handleLogoClicked(){
    store.eventBus.emit(MetaEvents.REPEAT_TO_HOME);
    this.$router.push({
      name:ViewNames.Home
    });
  }
}
