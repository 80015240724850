import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9930f48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-slot" }
const _hoisted_2 = { class: "image-slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createBlock(_component_el_image, {
    class: "meta-image",
    ref: "elImage",
    src: _ctx.src,
    alt: _ctx.alt,
    fit: _ctx.fit,
    onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('error'))),
    "preview-src-list": _ctx.previewSrcList,
    "preview-teleported": true,
    "initial-index": _ctx.initialIndex,
    loading: "lazy"
  }, {
    placeholder: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('loading')), 1)
    ]),
    error: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SvgIcon, { name: "image" })
      ])
    ]),
    _: 1
  }, 8, ["src", "alt", "fit", "preview-src-list", "initial-index"]))
}