import {createApp} from 'vue'
import App from './App.vue'
import router, {RouterMetaData} from './router'
import {ViewNames} from "@/router";
import i18n from './i18n'
import MetaButton from "@/components/MetaButton.vue";
import SlideI18nText from "@/components/SlideI18nText.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import MetaImage from "@/components/MetaImage.vue";
import store from "@/store";
import {configData, Envs, errorLog} from "@/config";

const app = createApp(App)
    .use(i18n)
    .use(router)
    .use(ElementPlus)
    .directive('containerRelateFs',(el:HTMLElement,binding) => {
        const {parent} = binding.modifiers;
        let container = el;
        if(parent && el.parentElement){
            container = el.parentElement;
        }
        const containerWidth = container.clientWidth;
        el.style.setProperty('font-size',containerWidth *  Number(binding.value) + 'px');
    })
    .directive('',()=>{

    })
    .component('MetaButton',MetaButton)
    .component('SlideI18nText',SlideI18nText)
    .component('SvgIcon',SvgIcon)
    .component('MetaImage',MetaImage)
    .mount('#app');





router.beforeEach(async (to,from,next) => {
    //规范meta类型
    const meta = to.meta as RouterMetaData;
    if(meta.requireAuth){
        const userInfo = await store.userInfoPromise;
        if(userInfo){
            next();
        }else{
            next({
                name:ViewNames.Login,
                query: {
                    redirect: to.fullPath
                }
            })
        }
        return;
    }

    if(meta.requireBackUrl){
        if(!store.backViewName){
            errorLog.dev('需要给store的backViewName字段赋值,用来做当前页面的返回页');
        }
    }
    next();
})
