export default {
  "3dRendering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D渲染"])},
  "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
  "aboutUsSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秘塔 — — 用AI将专业知识民主化"])},
  "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["风格设置"])},
  "annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背景"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础"])},
  "basicMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础会员"])},
  "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑色"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蓝色"])},
  "billingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单信息"])},
  "billingInfoPlanCircumstance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("planName")), "版，", _interpolate(_named("price")), "每", _interpolate(_named("span")), "，并且会在", _interpolate(_named("date")), "时续费。"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "canNotEmptyContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容不能为空"])},
  "clickToTry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单击以输入图片描述"])},
  "close-ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特写"])},
  "changeAvatarTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更换头像"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广场"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
  "copyrightComplaints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版权投诉"])},
  "copyTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制到剪切板"])},
  "copySpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制空间"])},
  "creations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作品"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
  "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额度"])},
  "currentCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前额度"])},
  "creditPerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额度每月"])},
  "canvasQuota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前画布的额度份额:"])},
  "canEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可编辑"])},
  "canView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可浏览"])},
  "creditPerMonthAndRelaxMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额度每月+队列模式"])},
  "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制链接"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距离"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载"])},
  "downloadInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载发票"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
  "emptyRecentsTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有最近图片"])},
  "canNotSetContentWithEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容不能为空"])},
  "freeUsePrivilege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商用和个人使用，100%免费"])},
  "formUserNameTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名长度2到16，可包含字母、数字、下划线和减号"])},
  "formEmailTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效的邮箱"])},
  "front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前方"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成画作"])},
  "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
  "gray": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["灰色"])},
  "hand-painted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手绘"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])},
  "illustration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["插画"])},
  "inviteCollaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请协作成员"])},
  "inpaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修补"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绘画"])},
  "inviteCollaboratorsTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以邀请你的朋友一起画任何东西，并可以实时看到对方的创作，赶快试试吧！"])},
  "limitedUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制使用"])},
  "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中"])},
  "longExposure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长曝光"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "logoFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秘塔捉捉猫"])},
  "microscopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["微小"])},
  "multipleExposures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多重曝光"])},
  "mainInputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入任何文字，我都将为您生成"])},
  "membershipTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含"])},
  "modelTrainingProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模型训练进度"])},
  "moneyBriefName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RMB"])},
  "moneyCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["￥"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
  "monthlyFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月费用"])},
  "moreExamples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多示例"])},
  "noMoreContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有更多内容了"])},
  "noCredits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的额度不足"])},
  "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开启"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确定"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
  "ownAllAssetsYouCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有所以你创建的资源"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建人"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "payAnnually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按年支付"])},
  "payMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按月支付"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的支付方式"])},
  "paymentMethod-credit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("credit")), "，尾号", _interpolate(_named("endingNumber"))])},
  "paymentMethodUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改"])},
  "perCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每额度"])},
  "perspective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视角"])},
  "pieceDeclaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此图片由秘塔捉捉猫生成"])},
  "photorealistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写实"])},
  "privateVisibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人可见"])},
  "privateVisibilityInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您创建的图片默认只对您可见。"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人主页"])},
  "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "promptPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入描述"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近"])},
  "publicToCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布到广场"])},
  "plain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平面"])},
  "recharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
  "rear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后方"])},
  "rechargePlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值版"])},
  "rechargeableInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值的额度只能在当月使用"])},
  "relaxMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["队列模式"])},
  "relaxModeInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以使用“队列”模式，该模式允许您无限制地生成图片，但会等待更长时间。在“队列”模式下，您将无法执行“高清化”操作。"])},
  "relaxModeTip_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预计等待时间为"])},
  "relaxModeTip_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time"))])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置密码"])},
  "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["侧方"])},
  "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请先登录"])},
  "signOut": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["登出", _interpolate(_list(0))])},
  "sketch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["素描"])},
  "standardMembership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标准会员"])},
  "standardPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标准版"])},
  "styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["风格"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
  "hdTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成高清图片将会花费您1点额度。"])},
  "subscribeCreditMetricInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*1描述 = 1额度（6张图片）；1张高清图片 = 1额度"])},
  "subscribeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买订阅"])},
  "subscribeUserCreditsLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["当前账户还剩", _interpolate(_named("num")), "点额度"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
  "subscriptionPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订阅"])},
  "subscriptionPlanCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "subscriptionPlanPrice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("price")), "/", _interpolate(_named("span"))])},
  "subscriptionReceipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅收据"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号"])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提示"])},
  "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上方"])},
  "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务协议"])},
  "unlimitedUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无限制使用"])},
  "trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人气"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转换"])},
  "tryItYourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己尝试"])},
  "upscale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高清"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])},
  "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白色"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红色"])},
  "viewAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有图片"])},
  "watercolour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水彩"])},
  "resetEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改邮箱"])}
}