import {CreationsImagesData} from "@/types/api/CreationImagesData";
import {configData} from "@/config";
import axios, {AxiosError} from 'axios';
import {GenerateImageResponse} from "@/types/api/GenerateImageData";
import {GenerateTaskData, RelaxTask, RequestGenerateTaskData} from "@/types/api/GenerateTaskData";
import {GenerateInpaintData} from "@/types/api/GenerateInpaintData";
import {colorLog, getCookie} from "@/utils";
import {CODE, Id, JavaResultData, PageData, ShareDTO} from "@/types/api/Common";
import {ShareUserData, UserAccount, UserInfoData} from "@/types/api/UserInfoData";
import {CommunityCreationData} from "@/types/CommunityCreationData";
import {
    CheckPhoneCodeParam, ExternalLoginParam, GetEmailCodeParam, GetPhoneCodeParam,
    LoginByAccountParam,
    LoginByPhoneParam,
    LoginByQrCodeParam,
    LoginValidParam, RegisterData
} from "@/types/api/LoginData";

import {ElMessage} from "element-plus";
import CreationData from "@/types/api/CreationData";
import {CanvasDTO} from "@/types/api/InfiniteData";
import {GenerateTransferImgData} from "@/types/api/GenerateTransferImgData";
import {AccountRecord} from "@/types/api/AccountData";
export enum RejectReason {
    REQUEST_ERROR = 'REQUEST_ERROR',
    ABORT = 'Abort'
}

const remote = axios.create({
    baseURL: configData.baseURL
});

const uranusRemote = axios.create();

type PollingCallbackFunction = (p:GenerateImageResponse) => void | boolean;
export type AbortReceiver = { abort: undefined | (() => void) };

export const getGeneratedImages = async (data:RequestGenerateTaskData,pollingCallback?:PollingCallbackFunction,receiveAbort?: AbortReceiver) => {
    const result = await remote.post<JavaResultData<GenerateTaskData>>('/image',data,{
        timeout:30000
    });
    if(result.data.code === 200){
        return getGenerateTask(result.data.data.task_id,pollingCallback,receiveAbort);
    }else{
        ElMessage.error(result.data.data as unknown as string);
        return Promise.reject(RejectReason.REQUEST_ERROR);
    }
}

export const generateRelaxModeImages = (data:RequestGenerateTaskData)=>{
    return remote.post<JavaResultData<RelaxTask>>('/image',data,{
        timeout:30000
    });
}

export const getCanvasGeneratedImages = async (canvasId:string|number,data:RequestGenerateTaskData,pollingCallback?:PollingCallbackFunction) => {
    const result = await remote.post<JavaResultData<GenerateTaskData>>(`/canvas/${canvasId}/image`,data,{
        timeout:30000
    });
    if(result.data.code === 200){
        return getGenerateTask(result.data.data.task_id,pollingCallback);
    }else{
        return Promise.reject(RejectReason.REQUEST_ERROR);
    }
}

export const getGenerateTask = (task_id:Id,pollingCallback?: PollingCallbackFunction,receiveAbort?: AbortReceiver) => {
    let proxyRes:(p:GenerateImageResponse) => void;
    let aborted = false;
    const pro = new Promise<GenerateImageResponse>((res,reject) => {
        proxyRes = res;
        if(receiveAbort){
            receiveAbort.abort = () => {
                aborted = true;
                reject();
            };
        }
    });

    const request = async () => {
        if(aborted) return;
        const result = await remote.get<JavaResultData<GenerateImageResponse>>(`/response/${task_id}`);
        if(aborted) return;
        pollingCallback && pollingCallback(result.data.data);
        if(result.data.data.flag === 1){
            proxyRes(result.data.data);
        }else {
            setTimeout(request,500);
        }
    }
    request();
    return pro;
}

export const getCreations:(pageNum:number,size?:number) => Promise<CreationsImagesData[]> = async (pageNum:number, size = 1) => {

    const result = await remote.get<JavaResultData<PageData<CreationsImagesData>>>('/creations',{params:{pageIndex:pageNum,pageSize:size}});
    return result.data.data.content;
}

export const getUserInfo = async () => {
    let result: UserInfoData | null;
    try{
        const res = await remote.get<JavaResultData<UserInfoData>>('/user-detail');
        result = res.data.data;
    }catch(e:unknown){
        if((e as AxiosError).response?.status === CODE.ERROR){
            ElMessage.error('Failed to get user info.');
        }
        result = null;
    }

    return result
}

export const getCommunityCreations = async (start:number,end:number,imgSize: 'small' | 'large',sortBy?: 'time') => {
    const result =  await  remote.get<JavaResultData<CommunityCreationData[]>>('/communities',{params:{start,end,imgSize,sortBy}})
    return result.data.data
}

export const updateCreationPublished = (id:string | number |undefined,published:boolean |undefined)=>{
    return remote.post(`/creation/${id}/published`,{published:published})
}

export const checkPhoneCode = (params:CheckPhoneCodeParam) => {
    return uranusRemote.get(`/verify`, { params: params })
};


export const loginByQrCode = (params:LoginByQrCodeParam) => {
    return uranusRemote.post(`/login`, params)
}

export const loginByAccount = (params:LoginByAccountParam)=>{
    return uranusRemote.post('/login',params)
}

export const loginByPhone = (params:LoginByPhoneParam)=>{
    return uranusRemote.post('/login',params)
}

export const logOut = () => {
    return remote.get("/logout");
}

export const getValid = (params:LoginValidParam) => {
    return uranusRemote.get(`/verify`, { params: params })
};

export const getQRCode = () => {
    return uranusRemote.get('/wx_metago_public_qr/metago');
}

export const getPhoneCode = (type:string, params:GetPhoneCodeParam) => {
    return uranusRemote.post(`/verify?type=${type}`, params)
};

export const getEmailCode = (type:string,params:GetEmailCodeParam)=>{
    return uranusRemote.post(`/verify?type=${type}`,params)
}

export const uploadImg = (params:any) => {
    const sid = getCookie('sid');
    const timestamp = Date.now();
    return uranusRemote.post('/upload', params,{params:{sid, timestamp}})
}

export const requestLogin = (source:string |null, userInfo:RegisterData, cb: (params: {user:any}) => void) => {
    return uranusRemote.post(`/user?s=${source}`, userInfo).then(res => {
        cb({ user: res.data })
    }).catch(res => {
        if (res.data) {
            cb({ user: res.data })
        } else {
            cb({ user: { msg: '服务器繁忙，请稍后再试' } })
        }
    })
}

export const externalLogin = (param:ExternalLoginParam) =>{
    return remote.post<JavaResultData<UserInfoData>>('/external-login',param)
}

export const changePassword = (pwd:string)=>{
    return axios.post('/password',{pwd:pwd})
}

export const updateUserInfo = (params:any) => {
    return axios.patch(`/user/${params.uid}`, params)
}

export const generateHighResolutionImage = async (id:CreationData['id'],pollingCallback?: PollingCallbackFunction) => {
    const result = await remote.post<JavaResultData<string>>(`/creation/${id}/high`);
    if(result.data.code === CODE.ERROR){
        throw 'noCredits';
    }
    const task_id = result.data.data;

    const HDResult = await getGenerateTask(task_id,pollingCallback);
    return HDResult.creationGroup.creations;
}

export const createCanvas = (name:string) =>{
    return remote.post<JavaResultData<CanvasDTO>>('/canvas',{name:name})
}

export const getCanvases = (pageIndex:number,pageSize:number)=>{
    return remote.get<JavaResultData<PageData<CanvasDTO>>>('/canvas',{params:{pageIndex:pageIndex,pageSize:pageSize}})
}

export const getCanvas = (id:string|number)=>{
    return remote.get<JavaResultData<CanvasDTO>>(`/canvas/${id}`)
}

export const getCanvasShareLink = (id:number|string)=>{
    return remote.get<JavaResultData<string>>(`/canvas/${id}/share`)
}

export const getCanvasShareUsers = (id:number|string)=>{
    return remote.get<JavaResultData<Array<ShareUserData>>>(`/canvas/${id}/share-user`)
}

export const getUserAccount = () => {
    return remote.get<JavaResultData<UserAccount>>(`/account`);
}

export const getCreditsHistory=(pageIndex:number,pageSize:number)=>{
    return remote.get<JavaResultData<PageData<AccountRecord>>>('/account-record',{params:{pageIndex:pageIndex,pageSize:pageSize}})
}

export const setCanvasQuotaNum = () => {

}

export const updateEditorChoice= (id:string|number,editorChoice:boolean)=>{
    return remote.post<JavaResultData<string>>(`/public-image/${id}/editor-choice`,{editorChoice:editorChoice})
}

export const getCreationShareLink = (id:Id) => {
    return remote.get<JavaResultData<string>>(`/creation/${id}/share`);
}

export const getShare = (shareKey:string)=>{
    return remote.get<JavaResultData<ShareDTO>>(`/share/${shareKey}`)
}

export const getInpaintingImages = async (canvasId:string|number,data:GenerateInpaintData,pollingCallback?:PollingCallbackFunction) => {
    const result = await remote.post<JavaResultData<GenerateTaskData>>(`canvas/${canvasId}/inpaint`,data,{
        timeout:30000
    });
    if(result.data.code === 200){
        return getGenerateTask(result.data.data.task_id,pollingCallback);
    }else{
        return Promise.reject(RejectReason.REQUEST_ERROR);
    }
}

export const getExampleImages = async () => {
    return remote.get<JavaResultData<CommunityCreationData[]>>('/sample');
}

export const getLoadingDisplayingImages = async () => {
    const res = await remote.get<JavaResultData<CommunityCreationData[]>>('/trending');
    return res.data.data;
}

export const getTransferImages = async (canvasId:string|number,data:GenerateTransferImgData,pollingCallback?:PollingCallbackFunction) => {
    const result = await remote.post<JavaResultData<GenerateTaskData>>(`canvas/${canvasId}/transfer`,data,{
        timeout:30000
    });
    if(result.data.code === 200){
        return getGenerateTask(result.data.data.task_id,pollingCallback);
    }else{
        return Promise.reject(RejectReason.REQUEST_ERROR);
    }
}

export const updateRelaxMode = (relax:boolean)=>{
    return remote.post('/relax',{relax:relax})
}

export const getRelaxTask = (id:Id)=>{
    return remote.get<JavaResultData<RelaxTask>>(`/relax-task/${id}`)
}
