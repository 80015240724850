
import {Options, Vue} from 'vue-class-component';
import MetaPopper from "@/components/MetaPopper.vue";
import {colorLog} from "@/utils";
import {MetaLocales} from "@/types/Lang";
import store from "@/store";

type langData = {i18nKey:string,label:string,optionLabel:string}

@Options<I18nSelector>({
  components: {
    MetaPopper
  },
  props: {
    popperOptions:{
      type:Object,
      required:false,
      default:{}
    },
  },
  computed:{
    label(){
      return this.languages.find((obj:langData) => obj.i18nKey === store.currentLang)?.label
    }
  }
})
export default class I18nSelector extends Vue {
  popperVisible = false;

  languages:langData[] = [
    {
      i18nKey: MetaLocales['ZH-CN'],
      label: '中文',
      optionLabel:'中文'
    },
    // {
    //   i18nKey:'zh-cht',
    //   label:'繁體中文'
    // },
    {
      i18nKey:MetaLocales.EN,
      label:'En',
      optionLabel:'English'
    },
  ];

  $refs!:{
    popper:MetaPopper
  }

  switchLanguage(lang:MetaLocales){
    store.updateCurrentLang(lang);
    this.$i18n.locale = lang;
    this.popperVisible = false;
  }

  handleBarClicked(){
    this.$refs.popper.popperInstance?.update();
    this.popperVisible = !this.popperVisible;
  }
  //
  // mounted(){
  //
  // }

}
