
import {Options, Vue} from 'vue-class-component';
import {createPopper, Instance, Modifier, OptionsGeneric, VirtualElement} from "@popperjs/core";
import {colorLog, DomEventListenerManager} from '@/utils';
import {Slot, Slots} from "vue";

@Options<MetaPopper>({
  components: {},
  props: {
    disabled:{
      required:false,
      default:false
    },
    popperContainer:{
      type:String,
      required:false,
      default:''
    },
    customWrapperClass:{
      type:String,
      required:false,
      default:''
    },
    customPopperClass:{
      type:String,
      required:false,
      default:''
    },
    visible:{
      type:Boolean,
      required:false,
      default:false,
    },
    zIndex:{
      required:false,
      type:String,
      default:'auto'
    },
    popperOptions:{
      required:false
    },
    virtualElement:{
      required:false,
      default:null
    },
    createAfterRefClicked:{
      required:false,
      type:Boolean,
      default:false
    }
  },
  watch:{
    visible:{
      handler:function(newVis:boolean){
        this.interVisible = newVis;

        this.popperInstance?.setOptions((options:any) => ({
          ...options,
          modifiers: [
            ...options.modifiers,
            { name: 'eventListeners', enabled: newVis },
          ],
        }));

        if(this.interVisible){
          const content = this.$refs!.popperRef as HTMLElement;
          this.domEventListenerManager.registerListener(window,'click',(event:MouseEvent) => {
            if((this.popperInstance?.state.elements.reference instanceof Element) && this.popperInstance?.state.elements.reference.contains(event.target as HTMLElement) || content.contains(event.target as (Node | null))) return;
            this.interVisible = false;
          },{capture:true});
        }else{
          this.domEventListenerManager.removeListener()
        }
      },
      immediate:true
    },
    interVisible:{
      handler(newVis:boolean){
        this.$emit('update:visible',newVis);
      }
    },
  },
  inject:['popperContainerId']
})
export default class MetaPopper extends Vue {
  zIndex!:string;
  disabled = false;
  popperContainer?:string;
  popperInstance?:Instance;
  popperOptions?:OptionsGeneric<any>;
  createAfterRefClicked!:boolean;
  customPopperClass?:string;
  customWrapperClass?:string;
  visible!:boolean;
  interVisible = false;
  domEventListenerManager = new DomEventListenerManager();
  virtualElement?:VirtualElement | null;
  popperContainerId!:string;

  mounted(){
    if(this.createAfterRefClicked){
      console.assert(this.virtualElement === null,'either createAfterRefClicked nor virtualElement is computed false');
      this.domEventListenerManager.registerListener(this.bar as HTMLElement,'click',() => {
        this.createPopper();
        this.domEventListenerManager.removeListener(this.bar as HTMLElement);
      })
    }else{
      this.createPopper();
    }
  }

  get bar(){
    const scopedContainer = this.$el.parentElement;
    return this.virtualElement || (scopedContainer!.getElementsByClassName(this.getSlotRefClassName())[0] as HTMLElement) || this.getSiblingElement();
  }

  createPopper() {
    const bar = this.bar;
    const content = this.$refs!.popperRef as HTMLElement;

    this.popperOptions?.modifiers?.forEach((modifier: Modifier<any, any>) => {
      if (!modifier || !modifier.options) return;
      if (Object.hasOwnProperty.call(modifier.options, 'boundary')) {
        if (!modifier.options) return;
        if (typeof modifier.options['boundary'] === 'function') {
          modifier.options['boundary'] = modifier.options['boundary']();
        }
      }
    })

    this.popperInstance = createPopper(bar, (content as HTMLElement), {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
      ...this.popperOptions
    });
  }

  getSlotRefClassName(){
    const ref = this.$slots!['default']!()[0];
    if(ref.props && ref.props.class){
      return ref.props.class;
    }
    return '';
  }

  getSiblingElement(){
    let element:Node | null = this.$el;
    while(!(element instanceof HTMLElement) && element !== null){
      element = element.nextSibling;
    }
    return element;
  }

  beforeUnmount(){
    this.popperInstance?.destroy();
    this.domEventListenerManager.destroy();
  }


}
