
import {Options, Vue} from 'vue-class-component';
import MetaHeader from "@/components/MetaHeader.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import store from "@/store";

@Options<NormalHeaderTemplate>({
  components: { MetaHeader,FadeTransition},
  props: {}
})
export default class NormalHeaderTemplate extends Vue {
  created(){
    store.updateRecentCreationNeedRefreshState(true);
  }
}
