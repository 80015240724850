export enum Envs {
    LOCAL,
    TEST_DEV,
    PROD,
    DEV
}

type ConfigData = {
    env:Envs,
    baseURL:string,
    socketUrl:string,
    beian:string
}

export const processEnv = process.env.NODE_ENV;

export const inviteCode = '';

export const configData:ConfigData = (() => {
    if(location.host === 'drawdraw.metasotalaw.cn'){
        return {
            env:Envs.DEV,
            baseURL:'/draw',
            socketUrl:'https://drawdraw.metasotalaw.cn/draw/socket',
            beian: "沪ICP备18046118号-2"
        }
    }
    else if(location.host === 'testdrawdraw.metasotalaw.cn'){
        return {
            env:Envs.TEST_DEV,
            baseURL:'/draw',
            socketUrl:'https://testdrawdraw.metasotalaw.cn/draw/socket',
            beian: "沪ICP备18046118号-2"
        }
    }
    else if(location.host === 'drawdraw.com'){
        return {
            env:Envs.PROD,
            baseURL:'/draw',
            socketUrl:'https://drawdraw.com/draw/socket',
            beian:'沪ICP备18046118号-7',
        }
    }
    {
        return {
            env:Envs.LOCAL,
            baseURL:'/api',
            socketUrl:'http://localhost:8003/socket',
            beian: "沪ICP备18046118号-2"
        };
    }

})();
const _errorLog = function (...args:any[]) {
    if(configData.env === Envs.LOCAL){
        console.error(...args);
    }
}
_errorLog.dev = (...args:any[]) => {
    console.error(...args);
}
export const errorLog = _errorLog as {
    (...args:any[]): void;
    dev(...args:any[]):void;
};

const config={
    inviteCode:'',
}

export default config
