
import anime from 'animejs';
import {Options, Vue} from 'vue-class-component';

@Options<MetaButton>({
  components: {},
  props: {}
})
export default class MetaButton extends Vue {

  animation?:anime.AnimeInstance;

  handleClicked(event:MouseEvent){
    const {pageX,pageY} = event;
    const {x,y,width,height} = this.$el.getBoundingClientRect();
    const offsetLeft = pageX - x;
    const offsetTop = pageY - y;

    const roundRadius = Math.max(x + width - pageX, pageX - x, y + height - pageY,pageY - y) * 2.4;
    this.animation?.pause();
    anime.set((this.$refs.wave as Element),{
      left:offsetLeft - (roundRadius / 2),
      top:offsetTop - (roundRadius / 2),
      scale:0,
      opacity:.5,
      width:roundRadius,
      height:roundRadius
    });

    this.animation = anime({
      targets:(this.$refs.wave as Element),
      scale:1,
      easing:'easeOutCubic',
      opacity:0,
      duration:400,
    });
  }
}
