import {createRouter, createWebHistory, RouteMeta, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue';
import {processEnv} from "@/config";
import NormalHeaderTemplate from "@/views/templates/NormalHeaderTemplate.vue";

export enum ViewNames {
    Home= 'Home',
    Detail = 'Detail',
    Subscribe = 'Subscribe',
    Individual = 'Individual',
    Creations = 'Creations',
    Profile = 'Profile',
    BillingInfo = 'BillingInfo',
    Community = 'Community',
    Fabric = 'Fabric',
    Login='Login',
    Login_en='Login_en',
    CanvasShare="canvasShare",
    Piece = 'Piece',
    AboutUs = 'AboutUs',
    TermsOfService = 'TermsOfService',
    CopyrightComplaints = 'CopyrightComplaints'
}
export type RouterMetaFields = 'requireAuth' | 'requireBackUrl' | 'noNeedHeader' | 'disableHeaderSticky';
export type RouterMetaData = Partial<Record<RouterMetaFields ,boolean>>;

const routes: Array<RouteRecordRaw> = [
    {
        path:'/',
        component: NormalHeaderTemplate,
        children:[
            {
                path: 'detail/:index',
                name:ViewNames.Detail,
                component:() => import('../views/Detail.vue'),
                meta:{
                    requireBackUrl:true,
                    disableHeaderSticky: true
                }
            },
            {
                path:'subscribe',
                name:ViewNames.Subscribe,
                component: () => import('../views/Subscribe.vue')
            },
            {
                path:'individual',
                name:ViewNames.Individual,
                redirect: to => {
                    return {
                        name:ViewNames.Creations
                    }
                },
                meta:{
                    requireAuth:true,
                },
                component: () => import('../views/IndividualHome/IndividualHome.vue'),
                children:[
                    {
                        path:'creations/:index?',
                        name:ViewNames.Creations,
                        component: () => import('../views/IndividualHome/Creations.vue'),
                        meta:{
                            requireAuth:true
                        }
                    },
                    {
                        path:'profile',
                        name:ViewNames.Profile,
                        component: () => import('../views/IndividualHome/Profile.vue')
                    },
                    {
                        path:'billingInfo',
                        name:ViewNames.BillingInfo,
                        component:() => import('../views/IndividualHome/BillingInfo.vue')
                    },
                    {
                        path:'aboutUs',
                        name:ViewNames.AboutUs,
                        component:() => import('../views/Official/AboutUs.vue')
                    }
                ]
            },
            {
              path:'official',
              component: () => import('../views/Official/Official.vue'),
              children:[
                  {
                      path: 'aboutUs',
                      name: ViewNames.AboutUs,
                      component: () => import('../views/Official/AboutUs.vue')
                  },
                  {
                      path: 'termsOfService',
                      name: ViewNames.TermsOfService,
                      component: () => import('../views/Official/TermsOfService.vue')
                  },
                  {
                      path: 'copyrightComplaints',
                      name: ViewNames.CopyrightComplaints,
                      component: () => import('../views/Official/CopyrightComplaints.vue')
                  }
              ]
            },
            {
                path:'community',
                name:ViewNames.Community,
                component:() => import('../views/Community.vue')
            },
            {
                path:'log-in',
                name:ViewNames.Login,
                component:() => import('../views/Login.vue')
            },
            {
                path:'log-in-en',
                name:ViewNames.Login_en,
                component:() => import('../views/Login_en.vue')
            },
            {
                path: '',
                name:ViewNames.Home,
                component:() => import('../views/Home.vue'),
                // alias:['/home']
            },
        ]
    },
    {
      path:'/piece',
      name: ViewNames.Piece,
      component: () => import('../views/Piece.vue')
    },
    {
        //404处理
        path:'/:pathMatch(.*)*',
        redirect:{name:ViewNames.Home},
    }
]

// if(processEnv === 'development'){
    routes.push(...[
        {
            path:'/test/fabric',
            name: ViewNames.Fabric,
            component: () => import('../views/test/Fabric.vue'),
            meta:{
                noNeedHeader:true
            }
        },
        {
            path:'/test/share',
            name: ViewNames.CanvasShare,
            component: () => import('../views/test/CanvasShare.vue'),
            meta:{
                noNeedHeader:true
            }
        }
    ])
// }

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
