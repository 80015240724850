export enum CODE {
    SUCCESS = 200,
    NO_AUTH = 401,
    NOT_FOUND = 404,
    ERROR = 500
}

export type Id = string | number;

export declare interface PythonResultData {
    code:CODE;
}

export declare interface JavaResultData<T = any> {
    code: CODE,
    data:T,
    message: string | null
}
export declare interface ImageSingletonMetaData {
    urlSmall:string;
    url1024?:string;
    url2048?:string;
}
export declare interface PageData<T = any>{
    content:Array<T>,
    totalElements:number,
    totalPages:number,
    numberOfElements:number

}

export declare interface ShareDTO<T = any>{
    shareId:string|number,
    shareType:string,
    data:T,
    url:string
}

export enum CanvasCollaborationAccessType {
    READ_ONLY,
    CAN_EDIT,
    OWNER
}