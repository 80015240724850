
import {Options, Vue} from 'vue-class-component';
import anime from "animejs";

@Options<RotateRing>({
  components: {},
  props: {}
})
export default class RotateRing extends Vue {

  $refs!:{
    path:HTMLElement;
  }
  mounted(){
    anime({
      targets: this.$refs.path,
      strokeDashoffset: [24, 118],
      easing: 'easeInOutSine',
      duration: 1500,
      direction:'reverse',
      delay: function(el, i) { return i * 250 },
      loop: true
    });
  }
}
