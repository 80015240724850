import {ElMessage} from "element-plus";
import moment from "moment";

export class DomEventListenerManager{
  constructor(){
    this.listeners = [];
  }

  registerListener(node,eventName,handler,...params){
    this.listeners.push({node,eventName,handler,params});
    node.addEventListener(eventName,handler,...params);
  }

  removeListener(...args){
    const [node,eventName,handler,...params] = args;
    const filteredListeners = [];
    const indexs = [];
    for(const index in this.listeners){
      const listener = this.listeners[index];
      if(
        (node ? listener.node === node : true)
        && (eventName || args.length >= 2 ? eventName === listener.eventName : true)
        && (handler || args.length >= 3 ? handler === listener.handler : true)
        && ((params && params.length) || args.length >= 4 ? params === listener.params : true)
      ){
        indexs.push(index);
        filteredListeners.push(this.listeners[index]);
      }
    }

    this.listeners = this.listeners.filter((item,index)=>{
      return indexs.indexOf(index) === -1;
    })

    while(filteredListeners.length){
      const listener = filteredListeners.pop();
      const {node,eventName,handler} = listener;
      const params = listener.params ? listener.params : [];
      if(node){
        node.removeEventListener(eventName,handler,...params);
      }
    }
  }

  destroy(){
    this.removeListener();

    for (const field in this) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        delete this[field];
      }
    }

    Object.setPrototypeOf(this, null);
  }
}


export function getSpecificParentElement(innerNode,judgeFn){
  /**
   * @type HTMLElement
   */
  let currentElement = innerNode;
  while(currentElement && !judgeFn(currentElement)){
    currentElement = currentElement.parentElement;
  }
  return currentElement;
}

/**
 * 获取某元素所在的滚动窗口
 * @param innerNode
 * @returns {HTMLElement}
 */
export function getScrollParentElement(innerNode){
  return getSpecificParentElement(innerNode,(element) => {
    return ['scroll','auto','overlay'].includes(getComputedStyle(element).overflowY)
  })
}

export function isMobile(){
  return navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i);
}

export const debounce = function (fn,wait) {
  let timer = null;
  return function (...args){
    let proxyRes;
    const pro = new Promise(res => proxyRes = res);
    if(timer){
      clearTimeout(timer);
    }

    timer = setTimeout(()=>{
      proxyRes(fn(...args));
      timer = null;
    },wait);
    
    return pro;
  }
}

const _colorLog = {

  resolveValue(args){
    typeof args[0] === 'object' ? args.unshift('Obj') : null
    return args[0];
  },
  produceStyle(color){
    //通用样式
    const universalStyle = 'color:#fff;border-radius:10px;padding:5px 5px;font-weight:bold';
    return `background:${color};${universalStyle}`;
  },
  proxyLog(color,args){
    console.log(`%c${this.resolveValue(args)}`,this.produceStyle(color),...args.slice(1));
  },
  log(...args){
    this.blue(...args);
  },
  red(...args){
    this.proxyLog('red',args);
  },
  blue(...args){
    this.proxyLog('blue',args);
  },
  orange(...args){
    this.proxyLog('orange',args);
  },
  pink(...args){
    this.proxyLog('pink',args);
  },
  green(...args){
    this.proxyLog('#40e569',args);
  },
  $1(...args){
    this.proxyLog('#00acec',args);
  },
  $2(...args){
    this.proxyLog('#edbf92',args);
  },
  $3(...args){
    this.proxyLog('#978e8e',args);
  },
  random(...args){
    this.proxyLog(`rgb(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)})`,args);
  }
}

const colorLogFn = (...args) => {
  _colorLog.red(...args);
}


export const colorLog = new Proxy(colorLogFn,{
  apply(target,context,args){
    target(...args);
  },
  get(target,name){
    if(name in _colorLog){
      return _colorLog[name];
    }else{
      return (...args)=>{
        _colorLog.proxyLog(name,args)
      }
    }
  }
})

export function copyToClipboard(s,callback) {
  if (window.clipboardData) {
    window.clipboardData.setData('text', s);
  } else {
    (function(s) {
      document.oncopy = function(e) {
        e.clipboardData.setData('text', s);
        e.preventDefault();
        document.oncopy = null;
      }
    })(s);
    document.execCommand('Copy');
  }
  callback && callback();
}


export const getHashColor=function(str){
  if(typeof str === 'string') {
    let hash = getHashCode(str);
    return colors[Math.abs(hash)%(colors.length)];
  }else if(typeof str === 'number'){
    return colors[str%(colors.length)];
  }

  return null;
}
export const getHashCode=function(str) {
  var hash = 0, i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const colors = [
  '#44acb6',
  '#9ccf5f',
  '#b0b6e3',
  '#b49cde',
  '#7ddfec',
  '#f7c100',
  '#51bd8a',
  '#f26092',
  '#c2c2c2',
  '#43d1e3',
  '#fedb08',
  '#7985ce',
  '#90a5b0',
  '#bdaca5',
  '#d5e449',
  '#bc65cb',
  '#aed77e',
  '#46c3f9',
  '#a3a3a3'
]


const showMessage = Symbol('showMessage')
/**
 *  重写ElementUI的Message
 *  single默认值true，因为项目需求，默认只弹出一个，可以根据实际需要设置
 */
class MetaMessage {
  success (options, single = true) {
    return this[showMessage]('success', options, single)
  }
  warning (options, single = true) {
    return this[showMessage]('warning', options, single)
  }
  info (options, single = true) {
    return this[showMessage]('info', options, single)
  }
  error (options, single = true) {
    return this[showMessage]('error', options, single)
  }

  [showMessage] (type, options, single) {
    if (single) {
      // 判断是否已存在Message
      if (document.getElementsByClassName('el-message').length === 0) {
        return ElMessage[type](options)
      }
    } else {
      return ElMessage[type](options)
    }
  }
}

/**
 *
 * @param date {Date | string}
 */
export function formatDate(date,locale = 'en'){
  const target = moment(date);
  const dayBeginning = moment(Date.now()).hour(0);
  const yearBeginning = moment((new Date()).getFullYear(),'YYYY');
  if(!target.isValid()){
    return '';
  }
  if(dayBeginning.isAfter(target)){
    if(yearBeginning.isAfter(target)){
      return target.format('YYYY-MM-DD');
    }else{
      if(moment.locale() === 'en'){
        return target.format('MMM Do');
      }else{
        return target.format('MMM D日');
      }
    }
  }else{
    return target.format('HH:mm');
  }
}
export function generateUUID(){
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export class BriefEmitter{
  constructor() {
    this.listeners = [];
  }

  emit(eventName,...args){
    this.listeners.forEach(({eventName:_eventName,handler,once},index)=>{
      if(_eventName === eventName){
        if(handler){
          handler(...args);
        }else{
          this.listeners.splice(index,1);
        }

        if(once){
          this.listeners.splice(index,1);
        }
      }
    })
  }

  on(eventName,handler,once){
    this.listeners.push({eventName,handler,once});
  }
  off(eventName,handler){
    const args = arguments;
    this.listeners.forEach((listener,index) => {
      if(
          (eventName ? eventName === listener.eventName : true)
          && (handler || args.length >= 2 ? handler === listener.handler : true)
      ){
        this.listeners.splice(index,1);
      }
    })
  }

  destroy(){
    this.off();
    for (const field in this) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        delete this[field];
      }
    }

    Object.setPrototypeOf(this, null);
  }
}


export function getCookie(name){
  var arr = document.cookie.match(
      new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  )
  if (arr != null) {
    return unescape(arr[2])
  }
  return null
}

export function deleteCookie(name,domain) {  /**根据cookie的键，删除cookie，其实就是设置其失效**/
let delString = `${name}=0;path=/;domain=${domain};expires=${new Date(0).toUTCString()}`;
  document.cookie = delString;
}

//判断是否包含中文
export const  isChn = (str) => {
  const reg=/^[\u4E00-\u9FA5]+$/;
  if (!reg.test(str)){
    return false ;
  }else{
    return true ;
  }
}
export { MetaMessage }
