import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02a25f7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "si-text"
}
const _hoisted_2 = {
  key: 1,
  class: "si-text"
}
const _hoisted_3 = {
  key: 2,
  class: "si-text"
}
const _hoisted_4 = {
  key: 0,
  class: "si-text"
}
const _hoisted_5 = {
  key: 1,
  class: "si-text"
}
const _hoisted_6 = {
  key: 2,
  class: "si-text"
}
const _hoisted_7 = {
  key: 2,
  class: "si-holder"
}
const _hoisted_8 = {
  key: 3,
  class: "si-holder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["slide-i18n-text", {cn:_ctx.$i18n.locale === _ctx.MetaLocales['ZH-CN']}])
  }, [
    (!_ctx.template)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "i18n"
        }, {
          default: _withCtx(() => [
            (_ctx.$i18n.locale.toLocaleLowerCase() === 'zh-cn')
              ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, null, 512)), [
                  [_directive_t, {path: _ctx.path,locale:'zh-cn',args: _ctx.args}]
                ])
              : (_ctx.$i18n.locale.toLocaleLowerCase() === 'zh-cht')
                ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, null, 512)), [
                    [_directive_t, {path: _ctx.path,locale:'zh-cht',args: _ctx.args}]
                  ])
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$tc(_ctx.path,_ctx.count, {
            locale:'en',
            ..._ctx.args
          })), 1))
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_Transition, {
          key: 1,
          name: "i18n"
        }, {
          default: _withCtx(() => [
            (_ctx.$i18n.locale.toLocaleLowerCase() === 'zh-cn')
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.template['zh-cn'][_ctx.path]), 1))
              : (_ctx.$i18n.locale.toLocaleLowerCase() === 'zh-cht')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.template['zh-cht'][_ctx.path]), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.template['en'][_ctx.path]), 1))
          ]),
          _: 1
        })),
    (!_ctx.template)
      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$tc(_ctx.path,_ctx.count, {..._ctx.args})), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.template[_ctx.$i18n.locale.toLocaleLowerCase()][_ctx.path] || _ctx.template[_ctx.$i18n.fallbackLocale.toLocaleLowerCase()][_ctx.path]), 1))
  ], 2))
}