
import {Options, Vue} from 'vue-class-component';
import {colorLog} from "@/utils";
import {PropType} from "vue";
import {ElImage} from "element-plus";

@Options<MetaImage>({
  components: {},
  props: {
    src:{
      required:true,
      type:String
    },
    alt:{
      required:false,
      default:''
    },
    lazy:{
      required:false,
      default:false
    },
    fit:{
      required:false,
      type: String as PropType<'fill' | 'contain' | 'cover' | 'none' | 'scale-down'>,
      default:'cover'
    },
    previewSrcList:{
      required:false,
      default:null,
      type:[Array,null]
    },
    initialIndex:{
      required:false,
      default:0,
      type:Number
    }
  }
})
export default class MetaImage extends Vue {
  src!:string;
  alt!:string;
  fit!:'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  initialIndex!:number;
  previewSrcList!:string[] | null;
  $refs!:{
    elImage: typeof ElImage
  }

}
